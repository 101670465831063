import React from "react"
import {
  BsFacebook,
  BsSpotify,
  BsInstagram,
  BsFillEnvelopeFill
} from "react-icons/bs"
import { IoLogoYoutube } from "react-icons/io"
import { SiApplemusic } from "react-icons/si"
import { SiTidal } from "react-icons/si"

import * as socialStyles from "../styles/components/socials.module.scss"

const SocialIcons = () => (
  <div className={socialStyles.socials}>
    <div className={socialStyles.social__icons}>
      <a
        href="https://open.spotify.com/artist/7rPScJjiLyGtwgcMX6vf9d"
        target="_blank"
        rel="noreferrer"
      >
        <BsSpotify className={socialStyles.social__icon} />
      </a>
      <a
        href="https://music.apple.com/artist/1621418310"
        target="_blank"
        rel="noreferrer"
      >
        <SiApplemusic className={socialStyles.social__icon} />
      </a>
      <a
        href="https://tidal.com/browse/artist/47029679?u"
        target="_blank"
        rel="noreferrer"
      >
        <SiTidal className={socialStyles.social__icon} />
      </a>
      <a
        href="https://www.instagram.com/heatherbandpei/"
        target="_blank"
        rel="noreferrer"
      >
        <BsInstagram className={socialStyles.social__icon} />
      </a>
      <a
        href="https://www.youtube.com/channel/UCZzcs3J6sDWEVsdolvPsUrA"
        target="_blank"
        rel="noreferrer"
      >
        <IoLogoYoutube className={socialStyles.social__icon} />
      </a>
      <a href="mailto:contact@heatherband.ca" target="_blank" rel="noreferrer">
        <BsFillEnvelopeFill className={socialStyles.social__icon} />
      </a>
    </div>
  </div>
)

export default function Socials(props) {
  var footer = props.footer ?? false
  return <SocialIcons footer={footer} />
}
